
.awesome-icon {
    width: 1.4em;

    height: 1.2em;
    vertical-align: -.2em;

}

.awesome-js-icon {

}

.awesome-inject-icon {

    fill: currentColor;
}

.awesome-icon svg {

    fill: currentColor;
}

.awesome-icon.awesome-2x  {
    width: 2rem;
    height: 2rem;

}

.awesome-icon.awesome-3x  {
    width: 3rem;
    height: 3rem;

}

.awesome-icon.awesome-5x  {
    width: 5rem;
    height: 5rem;

}



.awesome-icon.awesome-spin {
    animation-name: fa-spin;
    animation-direction: normal;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes fa-beat {
    0%, 90% {
        -webkit-transform: scale(1);
        transform: scale(1); }
    45% {
        -webkit-transform: scale(var(--fa-beat-scale, 1.25));
        transform: scale(var(--fa-beat-scale, 1.25)); } }
@keyframes fa-bounce {
    0% {
        -webkit-transform: scale(1, 1) translateY(0);
        transform: scale(1, 1) translateY(0); }
    10% {
        -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
        transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0); }
    30% {
        -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
        transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em)); }
    50% {
        -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
        transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0); }
    57% {
        -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
        transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em)); }
    64% {
        -webkit-transform: scale(1, 1) translateY(0);
        transform: scale(1, 1) translateY(0); }
    100% {
        -webkit-transform: scale(1, 1) translateY(0);
        transform: scale(1, 1) translateY(0); } }
@keyframes fa-fade {
    50% {
        opacity: var(--fa-fade-opacity, 0.4); } }
@keyframes fa-beat-fade {
    0%, 100% {
        opacity: var(--fa-beat-fade-opacity, 0.4);
        -webkit-transform: scale(1);
        transform: scale(1); }
    50% {
        opacity: 1;
        -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
        transform: scale(var(--fa-beat-fade-scale, 1.125)); } }
@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg); } }
@keyframes fa-shake {
    0% {
        -webkit-transform: rotate(-15deg);
        transform: rotate(-15deg); }
    4% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg); }
    8%, 24% {
        -webkit-transform: rotate(-18deg);
        transform: rotate(-18deg); }
    12%, 28% {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg); }
    16% {
        -webkit-transform: rotate(-22deg);
        transform: rotate(-22deg); }
    20% {
        -webkit-transform: rotate(22deg);
        transform: rotate(22deg); }
    32% {
        -webkit-transform: rotate(-12deg);
        transform: rotate(-12deg); }
    36% {
        -webkit-transform: rotate(12deg);
        transform: rotate(12deg); }
    40%, 100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); } }
.awesome-icon.awesome-beat {
    animation-name: fa-beat;

    animation-direction: normal;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.awesome-icon.awesome-fail svg {
    animation: failcolor 2s infinite;
}

@keyframes failcolor {
    0% {
        fill: gray;
    }

    50% {
        fill: lightgray;
    }

    100% {
        fill: gray;
    }
}